'use client'
import React from 'react'

import { PoolSkeleton, Pool as PoolComponent } from './components'

import { usePools, useUserData, type PoolResult } from './utils'


type PoolProps = {
  className?: string
  data: PoolResult
}

const Pool: React.FC<PoolProps> = (props) => {
  const { className, data } = props

  const { data: userData } = useUserData({
    chainId: data.chainId,
    poolAddress: data.address,
  })

  const poolData = {
    ...data,
    userData,
  }

  return (
    <PoolComponent className={className} data={poolData} />
  )
}

const Pools: React.FC = () => {
  const { isFetching, pools } = usePools()

  return (
    <div className="grid lg:grid-cols-2 gap-2">
      {
        isFetching ? (
          <>
            <PoolSkeleton />
            <PoolSkeleton />
          </>
        ) : (
          pools?.map((pool) => (
            <Pool
              key={pool.address}
              data={pool}
            />
          ))
        )
      }
    </div>
  )
}

export default Pools
