import { constants } from 'helpers'
import type { ChainId } from 'helpers/enums'


export const getExplorerAddressLink = (address: string, chainId: number) => {
  const explorerEndpoint = constants.chainsMap[chainId as ChainId]?.blockExplorerUrls[0]

  return `${explorerEndpoint}/address/${address}`
}

export const getExplorerTxLink = (txHash: string, chainId: number) => {
  const explorerEndpoint = constants.chainsMap[chainId as ChainId]?.blockExplorerUrls[0]

  return `${explorerEndpoint}/tx/${txHash}`
}
