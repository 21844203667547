import { useMemo } from 'react'
import { useApolloClients } from 'contexts'
import { useWallet } from 'wallet'
import { type NftsQuery, useNftsLazyQuery } from 'graph/liquidity'
import { fetchAndAdjustNftsValues } from 'helpers'
import { useQuery } from '@tanstack/react-query'
import type { AdjustedNFT } from 'helpers/fetchAndAdjustNftsValues'


export type Nft = AdjustedNFT

type Props = {
  poolAddress?: string
}

const useNfts = (props?: Props) => {
  const { poolAddress } = props || {}
  const { account } = useWallet()
  const clients = useApolloClients()

  const variables = useMemo(() => ({
    where: {
      owner: account?.toLowerCase(),
      isFullyWithdrawn: false,
    },
  }), [ account ])

  const [ fetchGnosisNfts ] = useNftsLazyQuery({
    variables,
    fetchPolicy: 'network-only',
    client: clients.gnosis,
  })

  const [ fetchPolygonNfts ] = useNftsLazyQuery({
    variables,
    fetchPolicy: 'network-only',
    client: clients.polygon,
  })

  const [ fetchChilizNfts ] = useNftsLazyQuery({
    variables,
    fetchPolicy: 'network-only',
    client: clients.chiliz,
  })

  const [ fetchArbitrumNfts ] = useNftsLazyQuery({
    variables,
    fetchPolicy: 'network-only',
    client: clients.arbitrum,
  })

  const [ fetchLineaNfts ] = useNftsLazyQuery({
    variables,
    fetchPolicy: 'network-only',
    client: clients.linea,
  })

  const queryFn = async () => {
    const [ polygonResponse, gnosisResponse, chilizResponse, arbitrumResponse, lineaResponse ] = await Promise.all([
      fetchPolygonNfts().catch(() => null),
      fetchGnosisNfts().catch(() => null),
      fetchChilizNfts().catch(() => null),
      fetchArbitrumNfts().catch(() => null),
      fetchLineaNfts().catch(() => null),
    ])

    const nfts: NftsQuery['nfts'] = [
      ...(polygonResponse?.data?.nfts || []),
      ...(gnosisResponse?.data?.nfts || []),
      ...(chilizResponse?.data?.nfts || []),
      ...(arbitrumResponse?.data?.nfts || []),
      ...(lineaResponse?.data?.nfts || []),
    ]

    return fetchAndAdjustNftsValues(nfts)
  }

  const { isFetching, data: nftsWithValues, refetch } = useQuery({
    queryKey: [ '/nfts-values' ],
    queryFn,
    enabled: Boolean(account),
  })

  const nfts = useMemo<Nft[]>(() => {
    if (!account || !nftsWithValues) {
      return [] as Nft[]
    }

    let result: Nft[] = []

    nftsWithValues.forEach((item) => {
      if (item && (!poolAddress || item.pool.address === poolAddress)) {
        result.push(item)
      }
    })

    return result
  }, [ account, nftsWithValues, poolAddress ])

  return {
    isFetching,
    nfts,
    refetch,
  }
}

export default useNfts
