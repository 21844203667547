export default {
  liquidity: {
    en: 'Liquidity',
  },
  tvlShort: {
    en: 'TVL',
  },
  apr: {
    en: 'Est. APR',
  },
  myLiquidity: {
    en: 'My liquidity provided',
  },
  nfts: {
    en: 'NFT’s',
  },
  estValue: {
    en: 'Est. value',
  },
  profit: {
    en: 'Profit',
  },
  tooltip: {
    en: 'Based on historical performance since the creation of the pool {date}.',
  },
  attentions: {
    temporaryDisabled: {
      en: 'New liquidity deposits temporarily disabled',
    },
    temporaryPaused: {
      en: 'The betting line on {chainName} is temporarily paused. The pool is not actively earning.',
    },
    beta: {
      en: 'The betting line on {chainName} is still in beta which can lead to higher volatility comparing to the other pools.',
    },
  },
  addLiquidity: {
    en: 'Add Liquidity',
  },
}
