import React from 'react'
import clsx from 'clsx'
import { constants } from 'helpers'
import { type ChainId } from 'helpers/enums'


type Size = 7 | 10 | 16

const sizesClassNameMap: Record<Size, { chain: string, token: string }> = {
  7: { chain: 'size-4', token: 'size-7' },
  10: { chain: 'size-5', token: 'size-10' },
  16: { chain: 'size-8', token: 'w-16 h-16' },
}

type PoolImagesProps = {
  chainId: number
  asset: string
  size: Size
  className?: string
}

const PoolImages: React.FC<PoolImagesProps> = ({ chainId, asset, size, className }) => {
  const classNames = sizesClassNameMap[size]

  const chainSrc = constants.chainsMap[chainId as ChainId]?.icon
  const tokenSrc = `/images/icons/tokens/${asset.toLowerCase()}.svg`

  return (
    <div className={clsx('relative', classNames.token, className)}>
      <img
        className={`absolute bottom-0 -right-1 ${classNames.chain} bg-white rounded-full`}
        src={chainSrc}
        alt=""
      />
      <img
        className={classNames.token}
        src={tokenSrc}
        alt=""
      />
    </div>
  )
}

export default PoolImages
