'use client'

import { useEffect } from 'react'
// import { Message } from '@locmod/intl'
import analytics from 'analytics'

// import { Href } from 'components/navigation'
// import Reminder from 'compositions/app/Reminder/Reminder'

import { Pools } from './components'
// import messages from './messages'


const LiquidityPoolsPage: React.FC = () => {
  useEffect(() => {
    analytics.track('liquidity pools page load')
  }, [])

  return (
    <>
      <Pools />
      {/* <Reminder className="mt-2 sm:mt-4">
        <Message value={{ ...messages.reward, components: { Href } }} tag="div" />
      </Reminder> */}
    </>
  )
}

export default LiquidityPoolsPage
