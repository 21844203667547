import { useQuery } from '@tanstack/react-query'
import { useWallet } from 'wallet'
import { useNfts } from 'graph/liquidity'


export type UserData = {
  nftCount: number
  estValue: number
  profit: number
  profitRate: number
}

type Props = {
  chainId: number
  poolAddress: string
}

const useUserData = ({ chainId, poolAddress }: Props) => {
  const { account } = useWallet()

  const { isFetching: areNftsFetching, nfts } = useNfts({
    poolAddress: poolAddress?.toLowerCase(),
  })

  const queryFn = async () => {
    if (nfts?.length) {
      let estValue = 0
      let profit = 0
      let depositedAmount = 0

      nfts.forEach((nft) => {
        estValue += nft.estValue || 0
        profit += nft.profit || 0
        depositedAmount += +(nft.depositedAmount || 0)
      })

      const profitRate = profit / depositedAmount * 100

      return {
        nftCount: nfts.length,
        estValue,
        profit,
        profitRate,
      }
    }
    else {
      return null
    }
  }

  const { isFetching: isUserDataFetching, data } = useQuery({
    queryKey: [ '/pool-user-data', poolAddress, nfts ],
    queryFn,
    enabled: Boolean(account && !areNftsFetching && nfts?.length),
  })

  return {
    isFetching: areNftsFetching || isUserDataFetching,
    data,
  }
}

export default useUserData
