import { useQuery } from '@tanstack/react-query'
import { restApi } from 'helpers/api'


export type AprPoolName =
  'Gnosis'
  | 'PolygonUSDT'
  | 'Arbitrum'
  | 'Linea'
  | 'Chiliz'

type ApiResult = {
  status: number
  data: {
    chain: AprPoolName
    apr: number
  }[]
}

export type UsePoolsAprResult = Record<AprPoolName, number>

const queryFn = async (): Promise<UsePoolsAprResult> => {
  try {
    const { data } = await restApi.get<ApiResult>('/apr')

    return data.data.reduce((acc, item) => {
      acc[item.chain] = item.apr

      return acc
    }, {} as UsePoolsAprResult)
  }
  catch (err: any) {
    return {} as UsePoolsAprResult
  }
}

const usePoolsApr = () => {

  return useQuery({
    queryKey: [ '/pools-apr' ],
    queryFn,
  })
}

export default usePoolsApr
