import clsx from 'clsx'


type PoolSkeletonProps = {
  className?: string
}

const PoolSkeleton: React.FC<PoolSkeletonProps> = ({ className }) => {

  return (
    <div className={clsx(className, 'bone w-full rounded-4 h-52')} />
  )
}

export default PoolSkeleton
