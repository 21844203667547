import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NftsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.LiquidityPoolNft_Filter>;
}>;


export type NftsQuery = { __typename?: 'Query', nfts: Array<{ __typename?: 'LiquidityPoolNft', id: string, nftId: string, depositedAmount: string, withdrawnAmount: string, createBlockTimestamp: string, pool: { __typename?: 'LiquidityPoolContract', id: string, address: string, chainId: number, asset: string, token: string, tvl: string, apr: string, tokenDecimals: number, withdrawTimeout: string, coreAddresses?: Array<string> | null } }> };


export const NftsDocument = gql`
    query Nfts($where: LiquidityPoolNft_filter) {
  nfts: liquidityPoolNfts(where: $where, subgraphError: allow) {
    id
    nftId
    depositedAmount
    withdrawnAmount
    createBlockTimestamp
    pool: liquidityPool {
      id
      address
      chainId
      asset
      token
      tvl
      apr
      tokenDecimals
      withdrawTimeout
      coreAddresses
    }
  }
}
    `;

/**
 * __useNftsQuery__
 *
 * To run a query within a React component, call `useNftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNftsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNftsQuery(baseOptions?: Apollo.QueryHookOptions<NftsQuery, NftsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NftsQuery, NftsQueryVariables>(NftsDocument, options);
      }
export function useNftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NftsQuery, NftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NftsQuery, NftsQueryVariables>(NftsDocument, options);
        }
export function useNftsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NftsQuery, NftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NftsQuery, NftsQueryVariables>(NftsDocument, options);
        }
export type NftsQueryHookResult = ReturnType<typeof useNftsQuery>;
export type NftsLazyQueryHookResult = ReturnType<typeof useNftsLazyQuery>;
export type NftsSuspenseQueryHookResult = ReturnType<typeof useNftsSuspenseQuery>;
export type NftsQueryResult = Apollo.QueryResult<NftsQuery, NftsQueryVariables>;