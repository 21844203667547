'use client'
import { Message } from '@locmod/intl'
import clsx from 'clsx'
import { constants } from 'helpers'

import { Profit } from 'components/dataDisplay'
import { Button } from 'components/inputs'

import messages from './messages'


type UserDataProps = {
  className?: string
  profitRate: number
}

const UserData: React.FC<UserDataProps> = ({ className, profitRate }) => {
  return (
    <div className={clsx(className, 'flex sm:flex-col items-center justify-between')}>
      <div className="text-label-16 flex items-center sm:mr-auto">
        <Message className="text-grey-80 font-medium" value={messages.yield} />
        <Button
          className="ml-1"
          to={`${constants.links.liquidity}/positions`}
          styling="quaternary"
          rightIcon="interface/chevron-right"
          size={20}
          title={messages.deposits}
        />
      </div>
      <Profit
        className="text-body-18 font-semibold mt-1 sm:mr-auto"
        value={profitRate}
        type="yield"
      />
    </div>
  )
}

export default UserData
