'use client'
import React from 'react'
import { useWallet } from 'wallet'
import { Message } from '@locmod/intl'
import { openModal } from '@locmod/modal'
import dayjs from 'dayjs'
import { constants, toLocaleString } from 'helpers'
import { ChainId } from 'helpers/enums'
import { getExplorerAddressLink } from 'helpers/getters'

import { Button } from 'components/inputs'
import { PoolImages } from 'components/dataDisplay'
import { Tooltip } from 'components/feedback'
import { Icon } from 'components/ui'

import UserData from './components/UserData/UserData'

import type { PoolProps } from './types'
import messages from './messages'


const Pool: React.FC<PoolProps> = (props) => {
  const { className, data } = props
  const {
    chainId, address, liquidityManager, token, tokenDecimals, asset, withdrawTimeout, tvl, apr, createdAt, userData, isDisabled,
  } = data

  const { account, chainId: userChainId } = useWallet()

  const isHidden = asset === 'USDC' && (ChainId.Polygon || chainId === ChainId.PolygonAmoy)

  if (isHidden) {
    return null
  }

  const isBeta = false

  // ATTN mb useful in future
  let attentionText
  let warningText

  if (isDisabled) {
    warningText = {
      ...messages.attentions.temporaryPaused,
      values: { chainName: constants.chainsMap[chainId as ChainId].chainName },
    }
  }

  if (isBeta) {
    warningText = {
      ...messages.attentions.beta,
      values: { chainName: constants.chainsMap[chainId as ChainId].chainName },
    }
  }

  const openContinueModal = () => {
    openModal('DepositModal', {
      chainId,
      asset,
      poolAddress: address,
      liquidityManager,
      token,
      tvl,
      apr,
      tokenDecimals,
      withdrawTimeout,
    })
  }

  const handleDepositClick = () => {
    if (account) {
      if (chainId !== userChainId) {
        openModal('SwitchNetworkModal', {
          chainId,
          onFinish: openContinueModal,
        })

        return
      }

      openContinueModal()
    }
    else {
      openModal('ConnectModal', {
        withSuccessModal: false,
        onFinish: () => openModal('SwitchNetworkModal', {
          chainId,
          onFinish: openContinueModal,
        }),
      })
    }
  }

  return (
    <div className="sm:p-6 -sm:p-4 bg-white rounded-4 border border-bg-30 shadow-lvl1 hocus:shadow-lvl2 transition group/card">
      <div className="flex -sm:flex-col items-start justify-between">
        <div className="flex items-center">
          <PoolImages
            className="mr-3 w-10"
            chainId={chainId}
            asset={asset}
            size={10}
          />
          <a
            href={getExplorerAddressLink(address, chainId)}
            className="text-body-18 font-semibold hocus:underline flex items-center group"
            target="_blank"
          >
            {asset} {constants.chainsMap[chainId as ChainId].chainName}
            <Icon className="sm:hidden size-4 ml-2 text-grey-70 group-hocus/card:block group-hocus:text-grey-90" name="interface/extrernal-link" />
          </a>
        </div>
        <Button
          title={messages.addLiquidity}
          className="-sm:w-full -sm:mt-4"
          styling="primary"
          rightIcon="interface/plus"
          size={44}
          onClick={handleDepositClick}
        />
      </div>
      <div className="mt-2 sm:mt-7 -mx-2 flex -sm:flex-col justify-between sm:items-baseline sm:flex-wrap">
        <div className="mx-2 flex sm:flex-col -sm:justify-between -sm:items-baseline sm:mr-3 mt-3 grow-[2] basis-0 flex-wrap">
          <Message className="text-label-16 text-grey-80 font-medium" value={messages.liquidity} />
          <div className="-sm:text-right text-body-18 font-semibold sm:mt-2">
            {toLocaleString(tvl)} {asset}
          </div>
        </div>
        <div className="mx-2 flex sm:flex-col -sm:justify-between mt-3 text-label-16 grow basis-0 flex-wrap">
          <div className="flex items-center whitespace-nowrap">
            <Message className="text-label-16 text-grey-80 font-medium" value={messages.apr} />
            <Tooltip
              text={{ ...messages.tooltip, values: { date: dayjs(+createdAt * 1000).utc().format('DD MMM YYYY') } }}
              placement="top"
              width={330}
            >
              <div className="w-fit ml-1 cursor-pointer text-grey-60 hocus:text-grey-80">
                <Icon className="size-4" name="interface/info-circle" />
              </div>
            </Tooltip>
          </div>
          <div className="text-body-18 font-semibold sm:mt-2">
            {isNaN(parseFloat(apr)) ? '--' : parseFloat(apr).toFixed(2)}%
          </div>
        </div>
        {
          Boolean(userData) && (
            <UserData className="mx-2 mt-3 grow basis-0" profitRate={userData!.profitRate} />
          )
        }
      </div>
    </div>
  )
}

export default Pool
