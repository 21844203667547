'use client'
import clsx from 'clsx'
import { toLocaleString } from 'helpers'

import { Icon } from 'components/ui'


type ProfitProps = {
  className?: string
  iconSizeClassName?: 'size-4' | 'size-5'
  value: string | number
  type: 'profit' | 'yield'
  asset?: string
}

const Profit: React.FC<ProfitProps> = ({ className, iconSizeClassName = 'size-5', value, type, asset }) => {
  const valueNum = parseFloat(String(value || 0))
  const absValue = Math.abs(valueNum)
  const isZero = !absValue || absValue < 0.01
  const textColor = isZero
    ? 'text-grey-90'
    : valueNum > 0 ? 'text-accent-green' : 'text-accent-red'

  const postfix = type === 'yield' ? '%' : ` ${asset || ''}`

  return (
    <div className={clsx('flex items-center', textColor, className)}>
      {
        !isZero && (
          <>
            {
              type === 'yield' ? (
                <Icon
                  className={iconSizeClassName}
                  name={valueNum > 0 ? 'interface/caret-up' : 'interface/caret-down'}
                />
              ) : (
                valueNum > 0 ? '+' : '-'
              )
            }
          </>
        )
      }
      {toLocaleString(absValue)}{postfix}
    </div>
  )
}

export default Profit
